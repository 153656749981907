import React, { useState } from 'react';
import "./Record.css";

// const isWithinLast24Hours = (timestamp) => {
//   const providedDate = new Date(timestamp);
//   const currentDate = new Date();
//   const differenceInMilliseconds = currentDate - providedDate;
//   const hours24InMilliseconds = 12 * 60 * 60 * 1000;
//   return differenceInMilliseconds <= hours24InMilliseconds;
// };

const Record = (props) => {
  const recordId = props.record.id

  // TEST RECENT UPDATE BADGE PILL
  // const recordLastUpdate = props.record.fields.UpdatedAt
  // const recentUpdate = isWithinLast24Hours(recordLastUpdate);

  const recordName = props.record.fields.Name
  const recordYear = props.record.fields.Year
  const recordIndividuals = props.record.fields.IndividualsName

  const recordMethods = props.record.fields.Method
  const recordMedias = props.record.fields.Media
  const recordThemes = props.record.fields.Theme

  const recordNotes = props.record.fields.Notes
  const recordFileURLs = props.record.fields.FileURLs
  const recordFileCaption = props.record.fields.Caption

  const recordLinksNames = props.record.fields.LinksName
  const recordLinksURLs = props.record.fields.LinksURL
  var aggregatedLinks = null;

  if (recordLinksNames && recordLinksURLs) {
    aggregatedLinks = recordLinksNames.map((name, index) => ({
      name: name,
      url: recordLinksURLs[index]
    }));
  }
  const [showMore, setShowMore] = useState(false);
  const toggleMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className={`row record ${showMore ? 'open-record' : ''}`} key={recordId}>
      <div className="col-12">
        <div className={`row record-title ${showMore ? 'open-title' : ''}`} onClick={toggleMore} style={{ cursor: 'pointer' }}>
          <div className="col-md-1">{recordYear}</div>
          <div className="col-md-6">
            <span className="work-title">
              <i>{recordName}</i>
              {/* TEST FOR SHOWING RECENT UPDATE {recentUpdate ? <span className="badge rounded-pill text-bg-light ms-2 notification">&nbsp;</span> : ''} */}
            </span>
          </div>
          <div className="col-md-5 text-md-end">{recordIndividuals.join(", ")}</div>
        </div>
        <div className={`row mt-1 more ${showMore ? 'show' : ''}`}>
          <div className="col-12">
            <div className="row mb-2">
              <div className="col-1"></div>
              <div className="col-sm-4">
                {recordThemes && recordThemes.map((theme, index) => (
                  <span key={theme} className="badge rounded-pill text-bg-secondary me-1">{theme}</span>
                ))}
              </div>
              <div className="col-sm-4 text-md-end">
                {recordMethods && recordMethods.map((method, index) => (
                  <span key={method} className="badge rounded-pill text-bg-light ms-sm-1 me-1">{method}</span>
                ))}
              </div>
              <div className="col-sm-3 text-md-end">
                {recordMedias && recordMedias.map((media, index) => (
                  <span key={media} className="badge rounded-pill text-bg-dark ms-sm-1 me-1">{media}</span>
                ))}
              </div>
            </div>
            <div className="row mt-3">
            <div className="col-md-1"></div>
              {recordFileURLs && recordFileURLs.map((url, index) => (
                <div key={index} className="col-md-2 col-sm-4 col-6 mb-3 mb-md-0">
                  <img src={url} alt={recordName} className="img-fluid" />
                </div>
              ))}
            </div>
            <div className="row mb-3">
            <div className="col-md-1"></div>
            <div className="col">
              {recordFileCaption && <span className="caption text-secondary">{recordFileCaption}</span>}
            </div>
            </div>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-11">
                <p className="mb-3">{recordNotes}</p>
                <ul className="list-unstyled">
                  {aggregatedLinks && aggregatedLinks.map((item, index) => (
                    <li key={index}>
                      <a  href={item.url} target="_blank" rel="noopener noreferrer" className="link-dark">{item.name}</a> ↗
                    </li>
                  ))}
                </ul>

                {/* <div className="card text-bg-dark border-dark mb-3">
                  <div className="card-body">
                    <code>BEAUTÉ Julie. Medea : Pour une écologie des ruines. Plan Libre. 2023, #205.</code>
                  </div>
                </div> */}

              </div>
            </div>
          </div>
        </div>
    </div>
    </div>
   
  );
};

export default Record;
