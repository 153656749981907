import axios from 'axios';

const baseURL = `https://api.airtable.com/v0/${process.env.REACT_APP_AIRTABLE_BASE_ID}/Works?view=Display%20on%20website`;

export const fetchAirtableData = async () => {
  try {
    let allRecords = [];
    let offset = null;
    const limit = 100; // Number of records per page

    do {
      const response = await axios.get(baseURL, {
        params: {
          pageSize: limit,
          offset: offset,
        },
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_TOKEN}`,
        },
      });

      const records = response.data.records.map(record => ({
        ...record,
        fields: {
          ...record.fields,
          FileURLs: record.fields.File ? record.fields.File.map(file => file.url) : [],
        },
      }));

      allRecords = allRecords.concat(records);
      offset = response.data.offset;
    } while (offset);

    return allRecords;
  } catch (error) {
    console.error("Error fetching data from Airtable", error);
    throw error;
  }
};
