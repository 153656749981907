import React, { useState } from 'react';
import "./Title.css";

const Title = () => {
  const [showMore, setShowMore] = useState(false);
  const toggleMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="container-fluid">
    <div className="container-fluid title">
        <div className="row" onClick={toggleMore} style={{ cursor: 'pointer' }}>
          <div className="col-12">
            <h1 className="title-text">Déprojeter Retisser</h1>
          </div>
        </div>
      <div className={`row more ${showMore ? 'show' : ''}`}>
          <div className="col-12 mt-1 mb-1">
            <p>
              "<i>Au-delà d'une certaine limite, il faut que l'histoire, la technique, le langage de la projection s'inversent : au lieu de projeter, il faut dé-projeter le monde.</i>" (Alessandro Mendini, <i>Notion de déprojet</i>, 1976). La notion de déprojet formulée par Alessandro Mendini ouvre une brèche. Elle installe la possibilité de quelque chose d'autre.
            </p>
            <p>
              De fait, les problématiques environnementales et sociales actuelles sont si graves et si totales qu’elles nécessitent de tout repenser. Après tout, est-ce qu’un·e architecte a vraiment besoin de construire pour être architecte ?
            </p>
            <p>
              La réponse la plus notoire à cette question a été donnée par les architectes Lacaton & Vassal. En 1996, à la demande d’embellissement de la Place Léon Aucoc par la Ville de Bordeaux, les architectes ont répondu “[qu’]<i>ici, l'embellissement n'a pas lieu d'être.</i> […] <i>Comme projet, nous avons proposé de ne rien faire d'autre que des travaux d'entretien, simples et immédiats : refaire la grave du sol, nettoyer plus souvent, traiter les tilleuls, modifier légèrement la circulation, …, de nature à améliorer l'usage de la place et à satisfaire les habitants.</i>”
            </p>
            <p>
              Lacaton & Vassal déplacent le projet ailleurs que dans la construction, et remettent en lumière le rôle des services techniques de la ville et de leurs moyens. Ils déprojettent leur rôle d’architecte en se mettant en retrait des attendus, et proposent de retisser sur cette place des éléments bien plus importants qu’une construction neuve : le soin des choses déjà-là, des personnes déjà présentes, de leurs actions et de leurs usages.
            </p>
            <p>
            Passé cet exemple quasi parfait de déprojet répété à l’envie, nous nous sommes confronté·es à l’aridité d’un domaine à investir. Reconsidérer les possibilités de faire projet, c’est avant tout dé-projeter le monde dans son ensemble, et pour mener cette lourde et permanente tâche il est indispensable de chercher des voix·voies au-delà du seul cosme de l’architecture.
            </p>
            <p>
              L’usage du préfixe <b>dé-</b>, “<i>servant à modifier le sens du terme primitif en exprimant l'éloignement, la privation, la cessation, la négation, la destruction de quelque chose</i>”, s’inscrit assez logiquement dans le contexte actuel : il est urgent d’arrêter de consommer des ressources qui n’existent pas, de se sevrer d’une source d’énergie—d’un sucre noir—qui nous réchauffe à toute allure. Mais pour compléter cette définition, nous pensons qu’il est utile de faire appel à un autre préfixe, le <b>re-</b> qui exprime un mouvement d’inversion, et plus particulièrement avec le verbe <i>retisser</i>.
            </p>
            <p>
              Avec ce double mouvement de <i>déprojection</i> et de <i>retissage</i>, il est possible d’élargir son champ de réflexion avant même la conception d’un quelconque projet. Il s’agit avant tout de questionner ce qui est déjà-là, d’accepter de remettre en question ses propres préconceptions, de cultiver l’étonnement.
            </p>
            <p>
              <span className="pa">DÉPROJETER RETISSER</span> est un projet de recherche initié par <span className="mo"><a href="https://mlav.land/" className="text-dark text-decoration-none" target="_blank" rel="noreferrer">MLAV.LAND</a></span> (Micro Laboratoire pour l'Architecture et le Vivant).
            </p>
          </div>
      </div>
    </div>
    </div>
  );
};

export default Title;
