import React, { useEffect, useState } from 'react';
import { fetchAirtableData } from './services/airtable';
import Record from './Record';
import './List.css';

const List = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const records = await fetchAirtableData();
        setData(records);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    getData();
  }, []);

  if (loading) return <div className={"loader"}></div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="container-fluid">
      <div className="row mb-5">
        <div className="col-12">
          {data.map((record) => (
            <Record key={record.id} record={record} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default List;
